import React, { useEffect } from "react"

function isClient() {
  return typeof window === "object"
}

export default function Presentation() {
  const url =
    "https://docs.google.com/presentation/d/e/2PACX-1vQ3YxYj_sfBToefnen7nVBmWts_fzNpifzyvl2sCD94O5BDz_jAQZPizrFeNBDPvef-2K3DKOinSMEG/pub?start=true&loop=false&delayms=50000"
  useEffect(() => {
    if (isClient()) {
      window.location.href = url
    }
  }, [])

  return <div />
}
